<template>
  <page-container title="关于本系统" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <h3 style=" background:white; width:100%; margin-top: 10px;font-size: 20px"><span style="margin: 20px">关于本系统</span></h3>
      <div class="box-container-inner">
        <a-form-model ref="userForm" :model="queryForms" :label-col="{span:9}" :wrapper-col="{span:6}" style="">
            <a-form-model-item label="平台版本" prop="platform_version">
              <a-input v-model.trim="platform_version" :read-only="true"/>
            </a-form-model-item>
<!--            <a-form-model-item label="运行环境" prop="jdkVersion">-->
<!--              <a-input v-model.trim="queryForms.jdkVersion" :read-only="true"/>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="操作系统" prop="osArch">-->
<!--              <a-input v-model.trim="queryForms.osArch" :read-only="true"/>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="内存总量" prop="total">-->
<!--              <a-input v-model.trim="queryForms.total" :read-only="true"/>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="空闲内存量" prop="free">-->
<!--              <a-input v-model.trim="queryForms.free" :read-only="true"/>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="磁盘空间总量" prop="disktotal">-->
<!--              <a-input v-model.trim="queryForms.disktotal" :read-only="true"/>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="剩余磁盘空间" prop="diskfree">-->
<!--              <a-input v-model.trim="queryForms.diskfree" :read-only="true"/>-->
<!--            </a-form-model-item>-->
            <a-form-model-item label="平台开发单位" prop="unit">
              <a-input v-model.trim="unit" :read-only="true"/>
            </a-form-model-item>
<!--            <a-form-model-item label="开发平台网站" prop="website">-->
<!--              <a-input v-model.trim="website" :read-only="true"/>-->
<!--            </a-form-model-item>-->
            </a-form-model>
      </div>
    </div>
  </page-container>
</template>

<script>
import {getSystemInfo, modifyPersonInfo} from 'A/xtpz.js'
import {getCache, getItemFromArrayByKey} from 'U'
import store from '@/store'
import { mapState } from 'vuex'

export default {
  name: 'userCenter',
  data() {
    return {
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '关于本系统',
          path: ''
        },
      ],
      platform_version:'2.0.9',
      unit:"南京优湖科技有限公司",
      website:"www.u-lake.com",
      queryForms:{
        disktotal:'',
        diskfree:'',
        total:'',
        free:'',
        jdkVersion:'',
        osArch:'',
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getSystemInfo().then(res=>{
        if(res&&res.returncode=='0'){
          this.queryForms.disktotal=parseFloat(res.disktotal).toFixed(2)+"GB"
          this.queryForms.diskfree=parseFloat(res.diskfree).toFixed(2)+"GB"
          this.queryForms.total=res.total
          this.queryForms.free=res.free
          this.queryForms.jdkVersion=res.jdkVersion
          this.queryForms.osArch=res.osArch
        }
      })
    },

  }
}
</script>
